<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="left">
        <el-form inline class="search-header" :model="searchData" label-width="100px">
          <el-form-item label="客户姓名">
            <el-input style="width:300px" v-model="searchData.memberName" placeholder="请输入客户姓名" size="small"></el-input>
          </el-form-item>
          <el-form-item label="明细类型">
            <el-select style="width:300px" v-model="searchData.billType" clearable placeholder="请选择" size="small">
              <el-option v-for="item in billTypeList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="交易流水号" v-show="isShow">
            <el-input style="width:300px" v-model="searchData.outTradeNo" placeholder="交易流水号" size="small"></el-input>
          </el-form-item>
          <el-form-item label="付款日期" v-show="isShow">
            <el-date-picker
                    style="width:300px"
                    size="small"
                    v-model="searchData.payTimeSlot"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="平台" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.sysCode" clearable placeholder="请选择" size="small">
              <el-option v-for="item in sysList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.merchantCode" clearable placeholder="请选择" size="small">
              <el-option v-for="item in merchantList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收支类型" v-show="isShow">
            <el-select style="width:300px" v-model="searchData.inOut" clearable placeholder="请选择" size="small">
              <el-option v-for="item in billInOutList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
        <el-button style="margin-left:10px" @click="getList" type="primary" icon="el-icon-search" size="small">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="btn-group">
      <div></div>
      <div>
        <el-button v-checkbtn="isShowBtn(AUTH_BTN.finance_bill_export)" type="primary" icon="el-icon-plus" size="small" @click="Export">导出</el-button>
      </div>
    </div>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
    <div style="height:50px"></div>
  </div>
</template>

<script>
import { getDictEnumListAPI } from '@/enum/dict.js'
import { getListAPI, removeAPI, exportAPI, } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '平台',
    prop: 'sysName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['sysName']}</p>
    }
  },
  {
    label: '原始流水号',
    prop: 'sourceSn',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['sourceSn']}</p>
    }
  },
  {
    label: '客户名称',
    prop: 'memberName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['memberName']}</p>
    }
  },
  {
    label: '商户',
    prop: 'merchantName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['merchantName']}</p>
    }
  },
  {
    label: '付款时间',
    prop: 'payTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['payTime']}</p>
    }
  },
  {
    label: '收支类型',
    prop: 'inOut',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['inOut'] == 1 ? '收入' : '支出'}</p>
    }
  },
  {
    label: '金额',
    prop: 'amount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['amount']}</p>
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['remark']}</p>
    }
  },
  {
    label: '明细类型',
    prop: 'billTypeName',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['billTypeName']}</p>
    }
  },
  {
    label: '订单号',
    prop: 'sysOrderSn',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['sysOrderSn']}</p>
    }
  },

  {
    label: '唯一码',
    prop: 'sysOrderFixSn',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['sysOrderFixSn']}</p>
    }
  },
  {
    label: '交易流水号',
    prop: 'outTradeNo',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['outTradeNo']}</p>
    }
  },
  {
    label: '操作',
    fixed: 'right',
    width: '100',
    customRender(h, row) {
      return (
          <div>
            <el-popconfirm disabled={row['isSuper'] == 1} title={`确定删除吗`} onConfirm={() => this.Remove(row['id'])}>
              <el-link
                v-show={this.isShowBtn('financeBillDel')}
                disabled={row['isSuper'] == 1}
                underline={false}
                style="margin-right:20px"
                type="primary"
                slot="reference">删除
              </el-link>
            </el-popconfirm>
          </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      isShow: false, //控制箭头
      columns,
      currentPage: 1,
      page_size: 50,
      total: 0,
      list: [],
      searchData: {
        memberName: '',
        billType: '',
        payTimeSlot: [],
        leftPayDate: null,
        rightPayDate: null,
        sysCode: '',
        merchantCode: '',
        inOut: null,
        outTradeNo: ''
      },
      sysList: [],
      merchantList: [],
      billTypeList: [],
      billInOutList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [start, start])
            }
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [end, end])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
    this.getDictEnumList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },

  methods: {
    async getList() {
      const {
        memberName,
        billType,
        payTimeSlot,
        leftPayDate,
        rightPayDate,
        sysCode,
        merchantCode,
        inOut,
        outTradeNo,
      } = this.searchData
      let params = {
        orderId: this.$route.query.orderId || '',
        memberName,
        billType,
        leftPayDate,
        rightPayDate,
        sysCode,
        merchantCode,
        inOut,
        outTradeNo,
        page: this.currentPage,
        pageSize: this.page_size
      }
      params.leftPayDate = payTimeSlot?.[0] || undefined
      params.rightPayDate = payTimeSlot?.[1] || undefined
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    Export() {
      const {
        memberName,
        billType,
        payTimeSlot,
        leftPayDate,
        rightPayDate,
        sysCode,
        merchantCode,
        inOut,
        outTradeNo,
      } = this.searchData
      let params = {
        memberName,
        billType,
        leftPayDate,
        rightPayDate,
        sysCode,
        merchantCode,
        inOut,
        outTradeNo,
      }
      params.leftPayDate = payTimeSlot?.[0] || undefined
      params.rightPayDate = payTimeSlot?.[1] || undefined
      exportAPI(params)
    },
    async Remove(id) {
      await removeAPI({ id }).then(() => {
        this.$message.success('删除成功')
        this.getList()
      })
    },
    async getDictEnumList() {
      this.sysList = await getDictEnumListAPI('identity:sys')
      this.merchantList = await getDictEnumListAPI('identity:merchant')
      this.billTypeList = await getDictEnumListAPI('finance:bill_type')
      this.billInOutList = await getDictEnumListAPI('finance:in_out')
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      flex: 1;
    }
    .right {
      margin-left: 20px;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
  }
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      // text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 40%;
      min-width: 400px;
    }
  }
  .el-divider {
    margin: 10px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-date-editor /deep/ {
    .el-range-separator {
      padding: 0;
    }
  }
}
</style>
