import { http } from '@/http/axios.js'
import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js'
import { Message } from "element-ui";
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/finance/bill/page',
    method: 'get',
    params
  })
}
// 删除
export function removeAPI(data) {
    return http({
        url: `/admin/finance/bill/delete`,
        method: 'post',
        data
    })
}
// 导出
export function exportAPI(data) {
  showScreenLoading()
  axios({
    method: 'post',
    url: '/api/admin/finance/bill/export',
    responseType: 'blob',
    data,
    headers: {
      Authorization: getToken() || ''
    }
  })
    .then(async res => {
      hideScreenLoading()
      let data = res.data
      if (!data || data.type !== 'application/vnd.ms-excel') {
          let response = await (new Response(data)).text();
          response = JSON.parse(response);
          Message.error(response?.message || '导出失败')
          return
      }
      let url = window.URL.createObjectURL(new Blob([data]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      let filename = res?.headers['content-disposition'].split('filename=').pop() || '财务明细.xlsx'
      a.setAttribute('download', decodeURIComponent(filename))
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a) //释放标签
    })
    .catch(error => {
      console.log(error)
    })
}
